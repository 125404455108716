
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";

// Initialises Page Animations
import { initDemo2 } from "../init_scripts";

export default function Demo2() {
    
    useEffect(() => {
        initDemo2();
    }, [])

    return (content);
}

const content = <>
    <main>
        <div className="frame">
            <div className="frame__title">
                <h1 className="frame__title-main">Inline Layout Switch</h1>
                <a aria-label="Back to the article" className="frame__title-back" href="https://tympanus.net/codrops/?p=65913">
                    <span className="oh__inner">Back to the article</span>
                    <svg width="16px" height="16px" viewBox="0 0 24 24"><path vectorEffect="non-scaling-stroke" d="M18.25 15.5a.75.75 0 00.75-.75v-9a.75.75 0 00-.75-.75h-9a.75.75 0 000 1.5h7.19L6.22 16.72a.75.75 0 101.06 1.06L17.5 7.56v7.19c0 .414.336.75.75.75z" />
                    </svg>
                </a>
            </div>
            <a className="frame__prev" href="http://tympanus.net/Development/3DTypeEffects">Previous demo</a>
            <nav className="frame__demos">
                <Link className="frame__demos-item" to="/index.html">Demo I</Link>
                <span className="frame__demos-item">Demo II</span>
            </nav>
        </div>
        <div className="grid">
            <div className="grid__item">
                <button className="back unbutton"><svg width={100} height={18} viewBox="0 0 50 9"><path vectorEffect="non-scaling-stroke" d="m0 4.5 5-3m-5 3 5 3m45-3h-77" /></svg></button>
            </div>
            <div className="grid__item">
                <div className="image">
                    <div className="image__inner" style={{ backgroundImage: 'url(img/1.jpg)' }} />
                </div>
            </div>
            <div className="grid__item">
                <div className="image">
                    <div className="image__inner" style={{ backgroundImage: 'url(img/2.jpg)' }} />
                </div>
            </div>
            <div className="grid__item">
                <div className="image">
                    <div className="image__inner" style={{ backgroundImage: 'url(img/3.jpg)' }} />
                </div>
            </div>
            <div className="grid__item">
                <div className="image">
                    <div className="image__inner" style={{ backgroundImage: 'url(img/4.jpg)' }} />
                </div>
            </div>
            <div className="grid__item">
                <div className="image">
                    <div className="image__inner" style={{ backgroundImage: 'url(img/11.jpg)' }} />
                </div>
            </div>
            <div className="grid__item">
                <div className="image">
                    <div className="image__inner" style={{ backgroundImage: 'url(img/9.jpg)' }} />
                </div>
            </div>
            <div className="grid__item">
                <div className="image">
                    <div className="image__inner" style={{ backgroundImage: 'url(img/8.jpg)' }} />
                </div>
            </div>
            <div className="grid__item">
                <div className="image">
                    <div className="image__inner" style={{ backgroundImage: 'url(img/7.jpg)' }} />
                </div>
            </div>
            <div className="grid__item">
                <div className="image">
                    <div className="image__inner" style={{ backgroundImage: 'url(img/6.jpg)' }} />
                </div>
            </div>
            <div className="grid__item">
                <div className="image">
                    <div className="image__inner" style={{ backgroundImage: 'url(img/10.jpg)' }} />
                </div>
            </div>
            <div className="grid__item">
                <div className="image">
                    <div className="image__inner" style={{ backgroundImage: 'url(img/12.jpg)' }} />
                </div>
            </div>
            <div className="grid__item">
                <div className="image">
                    <div className="image__inner" style={{ backgroundImage: 'url(img/13.jpg)' }} />
                </div>
            </div>
            <div className="grid__item">
                <div className="image">
                    <div className="image__inner" style={{ backgroundImage: 'url(img/14.jpg)' }} />
                </div>
            </div>
        </div>
        <div className="prime">
            <div className="image image--bg"><div className="image__inner" style={{ backgroundImage: 'url(img/bg2.jpg)' }} /></div>
            <div className="intro">
                <div className="image-wrap">
                    <div className="image">
                        <div className="image__inner" style={{ backgroundImage: 'url(img/14.jpg)' }} />
                    </div>
                </div>
                <span className="oh"><span className="oh__inner">She</span></span>
                <span className="oh"><em className="oh__inner">who</em></span>
                <div className="image-wrap">
                    <div className="image">
                        <div className="image__inner" style={{ backgroundImage: 'url(img/13.jpg)' }} />
                    </div>
                </div>
                <span className="oh"><span className="oh__inner">conquers</span></span>
                <span className="oh"><em className="oh__inner">herself</em></span>
                <span className="oh"><span className="oh__inner">is</span></span>
                <span className="oh"><span className="oh__inner">the</span></span>
                <span className="oh"><em className="oh__inner">mightiest</em></span>
                <div className="image-wrap">
                    <div className="image">
                        <div className="image__inner" style={{ backgroundImage: 'url(img/12.jpg)' }} />
                    </div>
                </div>
                <span className="oh"><span className="oh__inner">warrioress.</span></span>
                <div className="image-wrap">
                    <div className="image">
                        <div className="image__inner" style={{ backgroundImage: 'url(img/10.jpg)' }} />
                    </div>
                </div>
            </div>
            <div className="meta">
                <h3 className="meta__title">
                    <span className="oh"><span className="oh__inner">Mia</span></span>
                    <span className="oh"><span className="oh__inner">Le</span></span>
                    <span className="oh"><span className="oh__inner">Sage</span></span>
                </h3>
            </div>
            <Footer />
        </div>
    </main>

</>
