import { preloadImages, getInitialPosition, getDistance, map } from './utils';
import { gsap } from 'gsap';
import { ContentItem } from './contentItem';
import { Flip } from 'gsap/Flip';


const initDemo1 = ({setIsOpen }) => {
    // Register Plugin - Important
    gsap.registerPlugin(Flip);

    // body
    const body = document.body;

    // .content__item elements
    const contentItems = [...document.querySelectorAll('.content__item')];

    // For the purposes of the demo (which is to showcase the animation only) we'll hardcode the following.
    const contentItemsArr = [];
    contentItems.forEach(item => contentItemsArr.push(new ContentItem(item)));
    const contentItemCurrent = contentItemsArr.find(item => item.isCurrent());

    // .image elements
    const images = [...document.querySelectorAll('.prime .image')];
    // console.log(images);

    // total
    const totalImages = images.length;

    // .prime
    const prime = document.querySelector('.prime');

    // all .image-wrap. This is the original position af an image element bofore moving to the content item (Flip animation)
    const imageWraps = [...prime.querySelectorAll('.prime .image-wrap')];

    // View all projects link
    const viewAllProjects = prime.querySelector('.nav__item--active#product_1');

    // Quote texts that will get hidden when showing the images carousel
    const texts = [...prime.querySelectorAll('.oh > .oh__inner')];

    // .nav-wrap element that moves up/down when toggling the view
    const navWrap = document.querySelector('.nav-wrap');

    let isAnimating;

    // Animation / Show images carousel
    const showImages = () => {

        if (isAnimating) return;
        isAnimating = true;

        // Save current state of the images
        const flipstate = Flip.getState(images);
        
        const tl = gsap.timeline({
            onComplete: () => {
                // pointer events
                body.classList.add('content-open');
                isAnimating = false;
                setIsOpen(true);
            },
            defaults: {
                duration: 1.2,
                ease: 'power4.inOut',
            }
        })
            .addLabel('start', 0)
            .add(() => {
                // Append each image to each content item
                for (const [pos, contentItem] of contentItems.entries()) {
                    contentItem.appendChild(images[totalImages - 1 - pos]);
                }

                // flip them
                Flip.from(flipstate, {
                    duration: 1,
                    ease: 'expo.inOut',
                    absolute: true,
                    simple: true,
                    
                    spin: pos => {
                        if (pos === totalImages-1) return 0;
        
                        return pos%2 ? 1 : -1;
                    },
                   
                    stagger: -0.06
                });
            }, 'start+=0.15');

        for (const text of texts) {
            const xy = gsap.utils.random(0, 1, 1);

            tl.to(text, {
                xPercent: xy ? gsap.utils.random(0, 1, 1) % 2 ? -100 : 100 : 0,
                yPercent: xy ? 0 : gsap.utils.random(0, 1, 1) % 2 ? -100 : 100
            }, 'start');
        }

        tl
            .to(navWrap, {
                yPercent: 120 // a bit more than 100 so it doesn't end right below the viewport
            }, 'start')
            .to([contentItemCurrent.DOM.number, contentItemCurrent.DOM.title], {
                y: 0
            }, 'start+=0.3')
            .to(contentItemCurrent.DOM.back, {
                opacity: 1
            }, 'start+=0.3');

    }

    // Back animation
    contentItemCurrent.DOM.back.addEventListener('click', () => {
        
        if (isAnimating) return;
        isAnimating = true;

        // Save current state of the images
        const flipstate = Flip.getState(images);
        setIsOpen(false);

        const tl = gsap.timeline({
            onComplete: () => {
                body.classList.remove('content-open');
                isAnimating = false;
                document.querySelector(".grid").style.display = null;
            },
            defaults: {
                duration: 1.2,
                ease: 'power4.inOut',
            }
        })
            .addLabel('start', 0)
            .to([contentItemCurrent.DOM.number, contentItemCurrent.DOM.title], {
                y: '100%'
            }, 'start')
            .to(contentItemCurrent.DOM.back, {
                opacity: 0
            }, 'start')
            .to(navWrap, {
                yPercent: 0
            }, 'start')
            .to(texts, {
                xPercent: 0,
                yPercent: 0
            }, 'start')
            .add(() => {
                // Put images in their original positions
                prime.prepend(images[0]);
                for (const [pos, imageWrap] of imageWraps.entries()) {
                    imageWrap.appendChild(images[pos + 1]);
                }

                // flip them
                Flip.from(flipstate, {
                    duration: 1,
                    ease: 'expo.inOut',
                    absolute: true,
                    simple: true,
                    stagger: 0.06
                });
            }, 'start+=0.15');

    });

    viewAllProjects.addEventListener('click', () => {
        document.querySelector(".grid").style.display = "none";
        showImages();
        // console.log("Product 1");
    });

    // For Hover style
    for (const item of contentItemsArr) {
        if (!item.isCurrent()) {
            item.DOM.el.addEventListener('mouseenter', () => {
                gsap.to([item.DOM.number, item.DOM.title], {
                    y: 0
                });
            });
            item.DOM.el.addEventListener('mouseleave', () => {
                gsap.to([item.DOM.number, item.DOM.title], {
                    y: '100%'
                });
            });
        }
    }

    // Preload images
    preloadImages('.prime .image__inner').then(() => document.body.classList.remove('loading'));
}

const initDemo2 = ({setIsOpen}) => {
    // body
    const body = document.body;

    // .grid__item
    const gridItems = [...document.querySelectorAll('.grid__item')];

    // .prime (clip path animation will be on the element)
    const prime = document.querySelector('.prime');

    // image .image--bg
    const imageBg = prime.querySelector('.image--bg');

    // View all projects link
    const viewAllProjects = prime.querySelector('.nav__item--active#product_2');

    // back button
    const back = document.querySelector('.grid__item > .back')

    let isAnimating;

    // Animation / Show images carousel
    const showImages = () => {

        if (isAnimating) return;
        isAnimating = true;

        
        const tl = gsap.timeline({
            onComplete: () => {
                // pointer events
                body.classList.add('content-open');
                isAnimating = false;
                setIsOpen(true);
            },
            defaults: {
                duration: 1,
                ease: 'expo.inOut',
            }
        })
            .addLabel('start', 0)

        for (const gridItem of gridItems) {
            const { x, y } = getInitialPosition(gridItem);
            const delay = map(getDistance(gridItem), 0, 1000, 0, 0.4);

            tl.set(gridItem, {
                x: x,
                y: y,
                opacity: 0
            }, 'start')
                .to(gridItem, {
                    x: 0,
                    y: 0,
                    delay: delay,
                    opacity: 1
                }, 'start+=0')
        }

        tl.to(imageBg, {
            scale: 1.1
        }, 'start')
            .to(prime, {
                startAt: { clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' },
                clipPath: 'polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)'
            }, 'start')

    }

    // Back animation
    back.addEventListener('click', () => {

        if (isAnimating) return;
        isAnimating = true;
        setIsOpen(false);

        const tl = gsap.timeline({
            onComplete: () => {
                body.classList.remove('content-open');
                isAnimating = false;
                document.querySelector(".content").style.display = null;
            },
            defaults: {
                duration: 1,
                ease: 'expo.inOut',
            }
        })
            .addLabel('start', 0)
            .to(prime, {
                startAt: { clipPath: 'polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)' },
                clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'
            }, 'start+=0.4')
            .to(imageBg, {
                startAt: { scale: 1.4 },
                scale: 1
            }, 'start+=0.4');

        for (const gridItem of gridItems) {
            const { x, y } = getInitialPosition(gridItem);
            const delay = map(getDistance(gridItem), 0, 1000, 0.4, 0);

            tl.to(gridItem, {
                x: x,
                y: y,
                delay: delay,
                opacity: 0
            }, 'start')
        }

    });

    viewAllProjects.addEventListener('click', () => {
        document.querySelector(".content").style.display = "none";
        showImages();
        // console.log("Product 2");
    });

    // Preload images
    preloadImages('.image__inner').then(() => document.body.classList.remove('loading'));
}

export { initDemo1, initDemo2 };