
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { initDOM } from "./nav_script";


function NavMenu({isOpen}) {

    // const [DOM, setDOM] = useState({});
    // const [menuStatus, setMenuStatus] = useState({
    //     isOpen: false,
    //     isAnimating: false
    // })

    // const initDOM = ()=>{
    //     // DOM elements
    //     setDOM({
    //         // For demo purposes, trigger the effect when clicking any link in the menu (.line-link)
    //         menuLinks: [...document.querySelectorAll('.line-link')],
    //         // Cover element (wrap, outer and image inner elements)
    //         cover: {
    //             wrap: document.querySelector('.cover-wrap'),
    //             outer: document.querySelector('.cover'),
    //             inner: document.querySelector('.cover__inner'),
    //         },
    //         // Some of the main page content elements
    //         // We'll animate some of the content elements when expanding the menu
    //         content: {
    //             imgs: [...document.querySelectorAll('.content > .content__img')],
    //             titles: [...document.querySelectorAll('.content > .content__title')]
    //         },
    //         // Menu element (.menu)
    //         menu: document.querySelector('.menu'),
    //         // Element that slides out
    //         menuContent: document.querySelector('.menu__content'),
    //         // Close button
    //         closeCtrl: document.querySelector('.menu__back'),
    //         // Extra elements that will be animated inside the menu
    //         extra: document.querySelectorAll('.menu__tagline, .menu__social-author')
    //     });
    // }

    // // Menu expand
    // const expandMenu = () => {
    //     if (menuStatus.isAnimating || menuStatus.isOpen) return;
    //     setMenuStatus({ isOpen: true, isAnimating: true });
    //     menuTimeline.play();
    // };
    // // Menu collapse
    // const collapseMenu = () => {
    //     if (menuStatus.isAnimating || !menuStatus.isOpen) return;
    //     setMenuStatus({ isOpen: false, isAnimating: true });
    //     menuTimeline.reverse(0);
    // }

    // useEffect(()=>{
    //     if (DOM["menu"]!==undefined) {
    //         initTimeline();

    //         // Expand the menu when pressing any of the menu top links...
    //         DOM.menuLinks.forEach(link => {
    //             link.addEventListener('click', ev => {
    //                 ev.preventDefault();
    //                 expandMenu();
    //             });
    //         });

    //         // Collapse it when pressing the close button
    //         DOM.closeCtrl.addEventListener('click', ev => {
    //             ev.preventDefault();
    //             collapseMenu();
    //         });
    //     }
    // },[DOM]);

    const updateNavbar = (e) => {

        // This is the top bar
        let menuTop = document.querySelector(".menu__top")
        menuTop.style.transitionDuration = "0.6s";

        // Menu is closed
        if (!menuTop.classList.contains("menu_is_open")) {

            let start = 60, end = 100;
            if (e.y <= end && e.y >= start) {
                let opacity = (1 - (e.y / end)) * end / (end - start);
                // menuTop.style.opacity = opacity;
                menuTop.style.background = `rgb(238, 130, 51,${opacity})`;
            } else if (e.y < start) {
                // menuTop.style.opacity = 1;
                menuTop.style.background = `rgb(238, 130, 51, 1)`;
            } else {
                // menuTop.style.opacity = 0;
                menuTop.style.background = `rgb(238, 130, 51, 0)`;
            }
        }
        // Menu is open
        else {
            // menuContent.style.opacity = 1;
        }
    }

    useEffect(() => {
        initDOM();
        document.body.addEventListener("mousemove", updateNavbar);
    }, []);

    const navbar = <>

        <div className="cover-wrap" aria-hidden="true">
            <div className="cover">
                <div
                    className="cover__inner"
                    style={{ backgroundImage: "url(img/cover.jpg)" }}
                />
            </div>
        </div>

        <div className={`menu ${isOpen?"isOpen":"isClosed"}`}>
            <div className="menu__top">
                {/* <h2 className="menu__title">Asor</h2> */}
                <nav className="menu__nav-top">
                    <a href="#" className="line-link">Clothing</a>
                    <a href="#" className="line-link">Dresses</a>
                    <a href="#" className="line-link">Accessories</a>
                    <a href="#" className="line-link">Brands</a>
                </nav>
                {/* <div className="menu__side">
                    <a href="#" className="line-link">Login</a>
                    <button className="unbutton search" aria-label="Search">
                            <svg width={20} height={20} viewBox="0 0 20 20"><path d="M7.5 1.667A5.84 5.84 0 0 1 13.333 7.5 5.84 5.84 0 0 1 7.5 13.333 5.84 5.84 0 0 1 1.667 7.5 5.84 5.84 0 0 1 7.5 1.667M7.5 15a7.463 7.463 0 0 0 4.693-1.66l6.383 6.415a.833.833 0 0 0 1.181-1.176l-6.39-6.422A7.453 7.453 0 0 0 15 7.5 7.508 7.508 0 0 0 7.5 0 7.508 7.508 0 0 0 0 7.5 7.508 7.508 0 0 0 7.5 15" fill="#000" /></svg>
                        </button>
                </div> */}
            </div>
            <div className="menu__content-wrap">
                <div className="menu__content">
                    <nav className="menu__nav-content">
                        <div className="column">
                            <h4 className="column__title">New In</h4>
                            <a href="#" className="line-link">Blouses</a>
                            <a href="#" className="line-link">Jeans</a>
                            <a href="#" className="line-link">Blazers</a>
                            <a href="#" className="line-link">Trousers</a>
                            <a href="#" className="line-link">Lingerie</a>
                            <a href="#" className="line-link">Swimwear</a>
                            <h4 className="column__title">Discounts</h4>
                            <a href="#" className="line-link">T-Shirts</a>
                            <a href="#" className="line-link">Party</a>
                            <a href="#" className="line-link">Basics</a>
                        </div>
                        <div className="column">
                            <h4 className="column__title">Trending</h4>
                            <a href="#" className="line-link">New Season</a>
                            <a href="#" className="line-link">Holidays</a>
                            <a href="#" className="line-link">Going Out</a>
                            <a href="#" className="line-link">Workwear</a>
                        </div>
                    </nav>
                    <button className="menu__back unbutton">
                        <svg width={10} height={182} viewBox="0 0 10 121" preserveAspectRatio="xMidYMin meet"><path d="M5 0 .5 9h9L5 0Zm.5 120.5V8h-1v113h1v-.5Z" /></svg>
                    </button>
                    <div className="menu__social">
                        <a className="menu__social-link" href="http://eepurl.com/dJBUCo">subscribe</a>
                        <a className="menu__social-link" href="https://www.instagram.com/codropsss/">instagram</a>
                        <a className="menu__social-link" href="https://www.instagram.com/codropsss/">twitter</a>
                        <a className="menu__social-author" href="https://codrops.com">@codrops</a>
                    </div>
                    <h3 className="menu__tagline">Montebello</h3>
                </div>{/* /menu__content */}
            </div>{/* /menu__content-wrap */}
        </div>

    </>

    return (navbar);
}

export default NavMenu;
