
import { useEffect } from "react";

import { Routes, Route, useNavigate } from "react-router-dom";

// Components
import Demo1 from "./Demos/Demo1/Demo1.js";
import Demo2 from "./Demos/Demo2/Demo2.js";
import { GrowthBook } from "@growthbook/growthbook-react";
import NotFound from "./NotFound.js";
import OnePageDemo from "./Demos/OnePageDemo/OnePageDemo.js";

const features = {
  "status": 200,
  "features": {
    "scroll-types": {
      "defaultValue": 0,
      "rules": [
        {
          "variations": [
            1,
            2,
            3
          ],
          "coverage": 1,
          "weights": [
            0.334,
            0.333,
            0.333
          ],
          "key": "scroll-types",
          "hashAttribute": "id"
        }
      ]
    }
  },
  "dateUpdated": "2022-11-21T03:33:32.438Z"
}

function App() {


  const nav = useNavigate();

  // Growthbook
  // const growthbook = new GrowthBook({
  
  //   enableDevMode: true,
  //   trackingCallback: (experiment, result) => {
  //     console.log({
  //       experimentId: experiment.key,
  //       variationId: result.variationId
  //     })
  //   }
  // });

  // const initGrowthbook = async () => {
  //   await fetch("http://localhost:3100/api/features/prod_Sp64CG1GUX6BC3jiXX27Ls34Ns0p0UpReswRt30jYI\\\\\\\\")
  //     .then((res) => res.json())
  //     .then((json) => {
  //       growthbook.setFeatures(json.features);
  //     }).catch(err => {
  //       return;
  //     })

  
  //   growthbook.setAttributes({
  //     "id": "dfgfdgd",
  //     "deviceId": "foo",
  //     "company": "foo",
  //     "loggedIn": true,
  //     "employee": true,
  //     "country": "foo",
  //     "browser": "foo",
  //     "url": "foo"
  //   })
  //   return;
  // };



  // useEffect(() => {
  //   initGrowthbook().then(() => {
      

  //     switch (growthbook.feature("scroll-types").value) {
  //       case 1:
  //         nav("/index.html");
  //         break;

  //       case 2:
  //         nav("/index2.html");
  //         break;

  //       default:
  //         // nav("/");
  //         // initScrolling();
  //         break;
  //     }
  //   }).catch(err => { });
  // }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Demo1 />} />
        <Route path="/index.html" element={<Demo1 />} />
        <Route path="/index2.html" element={<Demo2 />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
