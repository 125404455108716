import { useState, useEffect } from "react";

const ProgressiveImg = ({ placeholderSrc, src, ...props }) => {
    const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setImgSrc(src);
        };
    }, [src]);

    const customClass =
        placeholderSrc && imgSrc === placeholderSrc ? "loading" : "loaded";

    return (
        <img
            {...{ src: imgSrc, ...props }}
            alt={props.alt || ""}
            className={`${customClass}`}
            style={{width:"100%", height:"100%"}}
        />
    );
};
export default ProgressiveImg;