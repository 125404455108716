function Footer() {
    return (
        <footer className="nav-wrap">
            <div className="logo">
                <span className="logo__years">1991 - 2022</span>
                <h2 className="logo__title">W&amp;B</h2>
            </div>
            <nav className="nav">
                <a className="nav__item nav__item--active" id="product_1">Product One</a>
                <a className="nav__item nav__item--active" id="product_2">Product Two</a>
                <a className="nav__item nav__item--active" id="product_3">Product Three</a>
            </nav>
            <nav className="social">
                <a className="social__item" href="https://twitter.com/codrops">tw</a>
                <a className="social__item" href="https://www.instagram.com/codropsss/">in</a>
                <a className="social__item" href="https://www.facebook.com/codrops/">fb</a>
            </nav>
        </footer>
    );
}

export default Footer;