
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";

import NavMenu from "../../Components/NavMenu";
import ProgressiveImg from "../../Components/ProgressiveImg";

// Initialization of Page Animations
import { initDemo1, initDemo2 } from "../init_scripts";

export default function Demo1() {

    const [demoVer, setDemoVer] = useState(1);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isOpen) {
            console.log("added");
            addEffect();
        }
    }, [isOpen]);

    useEffect(() => {
        addEffect();
        initDemo1({ setIsOpen });
        initDemo2({ setIsOpen });
    }, []);

    const middleCover = "image " + (isOpen ? "tiles__line-img" : "");
    const innerImage = "image__inner " + (isOpen ? "effect_img" : "");

    const content = <>
        <main className="demo1">
            <NavMenu isOpen={isOpen} />
            <div className="frame">
                <div className="frame__title">
                    <h1 className="frame__title-main">Inline Layout Switch</h1>
                    <a aria-label="Back to the article" className="frame__title-back" href="https://tympanus.net/codrops/?p=65913">
                        <span className="oh__inner">Back to the article</span>
                        <svg width="16px" height="16px" viewBox="0 0 24 24"><path vectorEffect="non-scaling-stroke" d="M18.25 15.5a.75.75 0 00.75-.75v-9a.75.75 0 00-.75-.75h-9a.75.75 0 000 1.5h7.19L6.22 16.72a.75.75 0 101.06 1.06L17.5 7.56v7.19c0 .414.336.75.75.75z" />
                        </svg>
                    </a>
                </div>
                <a className="frame__prev" href="http://tympanus.net/Development/3DTypeEffects">Previous demo</a>
                {/* <nav className="frame__demos">
                    <span className="frame__demos-item">Demo I</span>
                    <Link className="frame__demos-item" to="/index2.html">Demo II</Link>
                </nav> */}
                <nav className="frame__demos">
                    {demoVer == 1 ? <>
                        <span className="frame__demos-item">Demo I</span>
                        <a onClick={() => setDemoVer(2)} className="frame__demos-item">Demo II</a>
                    </> : null}
                    {demoVer == 2 ? <>
                        <a onClick={() => setDemoVer(1)} className="frame__demos-item">Demo I</a>
                        <span className="frame__demos-item">Demo II</span>
                    </> : null}
                </nav>
            </div>
            <div className="content">
                <div className="tiles__line content__item">
                    <div className="title-wrap">
                        <span className="number oh"><span className="oh__inner">01</span></span>
                        <h3 className="title oh"><span className="oh__inner">Grand Belle</span></h3>
                    </div>
                </div>
                <div className="tiles__line content__item">
                    <div className="title-wrap">
                        <span className="number oh"><span className="oh__inner">02</span></span>
                        <h3 className="title oh"><span className="oh__inner">Fiction Freak</span></h3>
                    </div>
                </div>
                <div className="tiles__line content__item content__item--current">
                    <div className="title-wrap">
                        <button className="back unbutton"><svg width={50} height={9} viewBox="0 0 50 9"><path vectorEffect="non-scaling-stroke" d="m0 4.5 5-3m-5 3 5 3m45-3h-77" /></svg></button>
                        <span className="number oh"><span className="oh__inner">03</span></span>
                        <h3 className="title oh"><span className="oh__inner">Frankie Low</span></h3>
                    </div>
                </div>
                <div className="tiles__line content__item">
                    <div className="title-wrap">
                        <span className="number oh"><span className="oh__inner">04</span></span>
                        <h3 className="title oh"><span className="oh__inner">Opion Release</span></h3>
                    </div>
                </div>
                <div className="tiles__line content__item">
                    <div className="title-wrap">
                        <span className="number oh"><span className="oh__inner">05</span></span>
                        <h3 className="title oh"><span className="oh__inner">War Herd</span></h3>
                    </div>
                </div>
            </div>
            <div className="grid">
                <div className="grid__item">
                    <button className="back unbutton"><svg width={100} height={18} viewBox="0 0 50 9"><path vectorEffect="non-scaling-stroke" d="m0 4.5 5-3m-5 3 5 3m45-3h-77" /></svg></button>
                </div>
                <div className="grid__item tiles__line p0_25">
                    <div className="image tiles__line-img">
                        <div className="effect_img image__inner" style={{ backgroundImage: 'url(img/1.jpg)' }} />
                    </div>
                </div>
                <div className="grid__item tiles__line p0_25">
                    <div className="image tiles__line-img">
                        <div className="effect_img image__inner" style={{ backgroundImage: 'url(img/2.jpg)' }} />
                    </div>
                </div>
                <div className="grid__item tiles__line p0_25">
                    <div className="image tiles__line-img">
                        <div className="effect_img image__inner" style={{ backgroundImage: 'url(img/3.jpg)' }} />
                    </div>
                </div>
                <div className="grid__item tiles__line p0_25">
                    <div className="image tiles__line-img">
                        <div className="effect_img image__inner" style={{ backgroundImage: 'url(img/4.jpg)' }} />
                    </div>
                </div>
                <div className="grid__item tiles__line p0_25">
                    <div className="image tiles__line-img">
                        <div className="effect_img image__inner" style={{ backgroundImage: 'url(img/11.jpg)' }} />
                    </div>
                </div>
                <div className="grid__item tiles__line p0_25">
                    <div className="image tiles__line-img">
                        <div className="effect_img image__inner" style={{ backgroundImage: 'url(img/9.jpg)' }} />
                    </div>
                </div>
                <div className="grid__item tiles__line p0_25">
                    <div className="image tiles__line-img">
                        <div className="effect_img image__inner" style={{ backgroundImage: 'url(img/8.jpg)' }} />
                    </div>
                </div>
                <div className="grid__item tiles__line p0_25">
                    <div className="image tiles__line-img">
                        <div className="effect_img image__inner" style={{ backgroundImage: 'url(img/7.jpg)' }} />
                    </div>
                </div>
                <div className="grid__item tiles__line p0_25">
                    <div className="image tiles__line-img">
                        <div className="effect_img image__inner" style={{ backgroundImage: 'url(img/6.jpg)' }} />
                    </div>
                </div>
                <div className="grid__item tiles__line p0_25">
                    <div className="image tiles__line-img">
                        <div className="effect_img image__inner" style={{ backgroundImage: 'url(img/10.jpg)' }} />
                    </div>
                </div>
                <div className="grid__item tiles__line p0_25">
                    <div className="image tiles__line-img">
                        <div className="effect_img image__inner" style={{ backgroundImage: 'url(img/12.jpg)' }} />
                    </div>
                </div>
                <div className="grid__item tiles__line p0_25">
                    <div className="image tiles__line-img">
                        <div className="effect_img image__inner" style={{ backgroundImage: 'url(img/13.jpg)' }} />
                    </div>
                </div>
                <div className="grid__item tiles__line p0_25">
                    <div className="image tiles__line-img">
                        <div className="effect_img image__inner" style={{ backgroundImage: 'url(img/14.jpg)' }} />
                    </div>
                </div>
            </div>
            <div className="prime tiles__line">
                <div className={"image--bg " + middleCover}>
                    <ProgressiveImg 
                        placeholderSrc={`img/${demoVer == 1 ? "bg_placeholder" : demoVer == 2 ? "bg2_placeholder" : ""}.jpg`}
                        src={`img/${demoVer == 1 ? "bg" : demoVer == 2 ? "bg2" : ""}.jpg`}
                        className={innerImage}
                    />
                    {/* <div
                        className={innerImage}
                        style={{
                            backgroundImage: `url(img/${demoVer == 1 ? "bg" : demoVer == 2 ? "bg2" : ""}.jpg)`
                        }} /> */}
                    {/* <img
                        className={innerImage}
                        src={`img/${demoVer == 1 ? "bg" : demoVer == 2 ? "bg2" : ""}.jpg`}
                        alt="" /> */}
                </div>
                <div className="intro">
                    <div className="image-wrap tiles__line">
                        <div className={middleCover}>
                            <div className={innerImage} style={{ backgroundImage: `url(img/${demoVer == 1 ? "1" : demoVer == 2 ? "14" : "bg"}.jpg)` }} />
                        </div>
                    </div>
                    <span className="oh"><span className="oh__inner">She</span></span>
                    <span className="oh"><em className="oh__inner">who</em></span>
                    <div className="image-wrap tiles__line">
                        <div className={middleCover}>
                            <div className={innerImage} style={{ backgroundImage: `url(img/${demoVer == 1 ? "2" : demoVer == 2 ? "13" : "bg"}.jpg)` }} />
                        </div>
                    </div>
                    <span className="oh"><span className="oh__inner">conquers</span></span>
                    <span className="oh"><em className="oh__inner">herself</em></span>
                    <span className="oh"><span className="oh__inner">is</span></span>
                    <span className="oh"><span className="oh__inner">the</span></span>
                    <span className="oh"><em className="oh__inner">mightiest</em></span>
                    <div className="image-wrap tiles__line">
                        <div className={middleCover}>
                            <div className={innerImage} style={{ backgroundImage: `url(img/${demoVer == 1 ? "3" : demoVer == 2 ? "12" : "bg"}.jpg)` }} />
                        </div>
                    </div>
                    <span className="oh"><span className="oh__inner">warrioress.</span></span>
                    <div className="image-wrap tiles__line">
                        <div className={middleCover}>
                            <div className={innerImage} style={{ backgroundImage: `url(img/${demoVer == 1 ? "4" : demoVer == 2 ? "10" : "bg"}.jpg)` }} />
                        </div>
                    </div>
                </div>
                <div className="meta">
                    <h3 className="meta__title">
                        <span className="oh"><span className="oh__inner">Mia</span></span>
                        <span className="oh"><span className="oh__inner">Le</span></span>
                        <span className="oh"><span className="oh__inner">Sage</span></span>
                    </h3>
                </div>
                <Footer />
            </div>
        </main>
    </>

    return (content);
}

// Adding Hover Effect
const addEffect = () => {
    const UPDATE = (x, y, CARD) => {
        const BOUNDS = CARD.getBoundingClientRect()
        // Calculate the range between the center and the pointer position.
        const posX = x - BOUNDS.x
        const posY = y - BOUNDS.y
        const ratioX = posX / BOUNDS.width
        const ratioY = posY / BOUNDS.height
        // CARD.style.setProperty('--x', posX)
        // CARD.style.setProperty('--y', posY)
        CARD.style.setProperty('--ratio-x', ratioX)
        CARD.style.setProperty('--ratio-y', ratioY)
        // console.info({ posX, posY })
    }     

    const CARDS = document.querySelectorAll(".tiles__line-img");
    console.log(CARDS);
    CARDS.forEach(CARD => {
        CARD.addEventListener("pointermove", ({ x, y }) => UPDATE(x, y, CARD));
    })
}

